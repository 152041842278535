<template>
    <table :class="classes">
        <slot />
    </table>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "min-w-full w-full table-auto",
        };
    },
    computed: {
        classes() {
            return `table ${this.baseClasses}`;
        },
    },
};
</script>
